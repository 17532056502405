<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>私人定制编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                
                <el-form  label-width="100px" ref="info" :model="info">


                    <el-form-item label="基地" prop="F_IN_JID_ID">
                        <el-select v-model="info.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_VR_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期" prop="F_IN_YINGQ_ID">
                        <el-select v-model="info.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期期限" prop="F_IN_YINGQQX_ID">
                        <el-select v-model="info.F_IN_YINGQQX_ID" placeholder="请选择营期期限" @change="selectChange()">
                            <el-option label="请选择营期期限" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingqx']" :label="v.F_VR_QISHU" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班级" prop="F_IN_YINGDBJ_ID">
                        <el-select v-model="info.F_IN_YINGDBJ_ID" placeholder="请选择班级" @change="selectChange()">
                            <el-option label="请选择班级" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['banj']" :label="v.F_VR_BANJMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学员" prop="F_IN_XUEYXJ_ID">
                        <el-select v-model="info.F_IN_XUEYXJ_ID" placeholder="请选择学员" @change="selectChange()">
                            <el-option label="请选择学员" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['xuanyxj']" :label="v.F_VR_XINGM" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="简介" prop="F_VR_JIANJ" :rules="[{required:true,message:'简介不能为空',trigger: 'blur' }]"><el-input placeholder="请输入简介" v-model="info.F_VR_JIANJ"></el-input></el-form-item>
                    <el-form-item label="原价" prop="F_VR_YUANJ" :rules="[{required:true,message:'原价不能为空',trigger: 'blur' }]"><el-input placeholder="请输入原价" v-model="info.F_VR_YUANJ"></el-input></el-form-item>
                    <el-form-item label="折后价" prop="F_VR_ZHEHJ" :rules="[{required:true,message:'折后价不能为空',trigger: 'blur' }]"><el-input placeholder="请输入折后价" v-model="info.F_VR_ZHEHJ"></el-input></el-form-item>

                    <el-form-item label="私人定制多图" prop="thumb" class="width_auto">
                        <el-upload :action="$api.integralUpload" :headers="upload_headers" list-type="picture-card" ref="upload" :file-list="file_list2" :limit="5" :multiple="true"  :on-success="handleAvatarSuccess" :on-exceed="onExceed" >
                            <!-- <i  class="el-icon-plus avatar-uploader-icon"></i> -->
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{file}">
                                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
                                <div class="is_master" :style="get_master_image(file)"><i class="el-icon-finished"> 主图</i></div>
                                <span class="el-upload-list__item-actions">
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="setMaster(file)" >
                                        <i class="el-icon-finished"></i>
                                    </span>
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handlePictureCardPreview(file)" >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)" >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>


                    <el-form-item label="详情描述" class="width_auto_70">
                        <wangeditor @goods_content="goods_content" :contents="info.F_TE_XIANGQMS"></wangeditor>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
                   
            </div>
        </div>



        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>


    </div>

</template>

<script>

import wangeditor from "@/components/seller/wangeditor.vue"
export default {
    components: {
        wangeditor,
    },
    props: {},
    data() {
      return {
          info:{
              goods_master_image:'',
          },
          class_list:[],
          upload_headers:{},
          file_list:[],
          file_list2:[], // element 内置file_list
          dialogVisible:false,// 是否打开预览
          dialogImageUrl:'', // 预览
          disabled:false,
          list:{},
      };
    },
    watch: {
        
    },
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    // 图片 验证
                    if(_this.file_list.length<=0){
                        return _this.$message.error('商品主图至少上传一张');
                    }

                    if(_this.info.F_IN_JIAG<=0 || _this.info.F_IN_SHENGYKCSL<0){
                        return _this.$message.error('价格或剩余课程数量没填写，或者填写错误！');
                    }else{
                        _this.add_goods();
                    }

                }
            })
        },
        
        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            if(this.file_list.length==0){
                this.info.goods_master_image = res.data;
            }
            this.file_list.push(res.data);
            this.$forceUpdate();
        },
        handlePictureCardPreview:function(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 文件删除
        handleRemove:function(file){
            // console.log(file,this.$refs.upload);
            this.$refs.upload.handleRemove(file);
            let index = this.file_list.indexOf(file.url);
            this.file_list.splice(index,1);
        },
        // 设置主图
        setMaster:function(file){
            // console.log(file);
            this.info.goods_master_image = file.url;
        },
        // 上传超过限制
        onExceed:function(){
            this.$message.error('商品展示图片不能超过5个');
        },
        
        get_master_image:function(file){
            if(file.url != undefined){
                // console.log(file.response.data,this.info.goods_master_image)
                if(file.url == this.info.goods_master_image){
                    return 'display:block';
                }else{
                    return 'display:none';
                }
            }
        },
        
        // 富文本编辑内容变化
        goods_content:function(data){
            this.info.F_TE_XIANGQMS = data;
        },
        // 添加商品
        add_goods:function(){
            let info = this.info;
            info.file_list = this.file_list; // 图片

            // 请求
            this.$post(this.$api.editSirdz+this.$route.params.id,info).then(res=>{
                if(res.code == 200){
                    this.$message.success('添加成功');
                    this.$router.go(-1);
                }else{
                    this.$message.error(res.msg);
                }
            });
        },
        
        
        // 获取添加初始化信息
        get_goods_add_info:function(){
            this.$get(this.$api.editSirdz+this.$route.params.id).then(res=>{
                if(res.code == 500){
                    this.$message.error(res.msg);
                    this.$router.go(-1);
                }else{
                    this.list = res.data;
                    this.info = res.data.sirdz;

                    this.file_list = res.data.sirdz.F_TE_TUP.split(',');
                    res.data.sirdz.F_TE_TUP.split(',').forEach(imgRes=>{
                        this.file_list2.push({name:'',url:imgRes});
                    });

                }
            });
        }
    },
    created() {
        this.get_goods_add_info();
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
    },
    mounted() {
        
    }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb{
    line-height: 32px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
}
.avatar-upload{
    width: 146px;
    height: 146px;
}

.is_master{
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    color:#fff;
    width: 164px;
    text-align: center;
    display: none;
}
.goods_class_add_left{
    float: left;
    margin-right: 15px;
    // border:1px solid #e1e1e1;
    padding:0 20px;
    border-radius: 5px;
    background: #f1f1f1;
}
</style>